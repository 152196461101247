.header_Wrapper{
    color: #fff;
    height: 50px;
    display: flex;
    align-items: center;
}
.bg-dark {
    background-color: #000;
}
@media (min-width:992px) {
    .navbar {
        padding: 1.5rem 0px;
    }
}
.navbar-dark .Nav_brand{
    letter-spacing: 0.015em;
    color: #B0B0B0;
    font-weight: 400;
font-size: 14px;
line-height: 14px;
white-space: normal;
}
.Navbar_length{
    margin-bottom: 0;
}

.navbar-dark .navbar-nav .nav-link,.navbar-dark .navbar-nav .nav-link:hover{
    font-size: 14px;
    color: #fff;
}
.navbar-toggler.collapsed .navbar-toggler-icon {
    height: 20px;
    width:  20px;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-toggler .navbar-toggler-icon {
    height: 20px;
    width:  20px;
    background-image: url("../../assets/images/close.png");
}
.navbar-dark .navbar-toggler{
    padding: 0.25rem 9px;

    border: 1px solid rgb(184, 171, 171);
}
.whitepaper-link,.whitepaper-link:hover{
    color: #fff;
    text-decoration: none;
}
#responsive-navbar-nav .dropdown-toggle::after{
    display: none;
}
.header-dropdown button{
    background-color: transparent;
padding: 0;
border: 0;
}
.anchor-link, .anchor-link:hover{
font-weight: 400;
font-size: 14px;
line-height: 14px;
letter-spacing: 0.015em;
color: #B0B0B0;
}
#responsive-navbar-nav .header-dropdown button:hover,#responsive-navbar-nav .header-dropdown button:focus{
    background-color: transparent;
    padding: 0;
    border: 0;
    box-shadow: none;
    outline: none;
}
@media (min-width:991px){
    #header_link .nav-link {
        padding-right: 0;
        padding-left: 1.5rem;
    }
.header-dropdown .dropdown-menu.show{
    left: -144px;
}
}
@media(min-width:992px) {
    #responsive-navbar-nav .flex40{
            display: flex;
            justify-content: end;
            flex: 43%;
            font-size: 19px;
    }
    .flex60{
            display: flex;
            justify-content: end;
            width: 56%;
    }
   #responsive-navbar-nav .flex60 .Nav_left_space{
        padding-right: 0px;
    }
    .soigner_title{
        align-items: center;
        width: 100%;
    }
}
@media(max-width:991px) {
    .navbar-nav {
        display: flex;
        flex-direction: column;
        padding-left: 0;
        margin-bottom: 0;
        align-items: center;
        list-style: none;
        background: #000;
        height: 90vh;
        justify-content: center;
    }
    .navbar-toggler{
        padding-right: 0;
    }
    .navbar{
        padding: 1.5rem 20px;
    }
}
