.Dashboard_Body {
  height: 100vh;
  overflow: auto;
  font-family: 'Cop';
  background-color: #000;
}
.container {
  max-width: 1320px;
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;
}
